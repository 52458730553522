import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0053 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="年次有給休暇に関する事例集クイズ。いくつ正解できますか？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.06.28</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        労働基準法の中でも労使ともに注目度の高い制度の１つに有給休暇があります。労働法制上の中で唯一、働いていないにも関わらず給与の支払い義務がある「休暇」は年次有給休暇のみです。今回は記憶に残りやすいクイズ形式で年次有給休暇について解説していきます。また、便宜上、年次有給休暇を年休と記します。
      </p>
      <img src="/images/column/details/c0053.jpg" alt="キンクラコラム画像53" />
      <h1 id="toc1">
        Q
        定年退職後再雇用された場合、勤続年数はリセットされ、年休の付与日数も最初からとなってしまいます。
      </h1>
      <p>
        A
        誤り。実質的に定年退職後も同一事業所に再雇用されているような場合は、例え高額な退職金を受け取り、書類上も一旦退職したような場合であっても
        <span>勤続年数は通算</span>
        されます。よって、年休の付与日数を決める際の勤続年数をリセットしてしまう取り扱いは誤りとなります。注意点として、近年は人事情報をIT端末で管理する企業が増えており、退職金や社会保険手続きのために一旦退職日を入力すると自動的に勤続年数がリセットされてしまうことがありますので、必ず確認しましょう。
      </p>

      <h1 id="toc2">
        Q
        先月から１日の労働時間が8時間に変わりました。１日５時間勤務の時に付与された年休がありますが、年休を使った際には5時間分の給与が支払われます。なお、年休の時の給与の払い方は所定労働時間に応じた給与を払うルールになっています。
      </h1>
      <p>
        A 誤り。
        <span>
          年休は付与された日の所定労働時間を基準とするのではなく、年休を取ったときの所定労働時間に応じた給与が支払われます。
        </span>
        つまり、このケースは8時間分の給与を払わなければ違法となります。参考までに年休の給与の払い方は３つあります。１つは最もスタンダードなパターンである本設例のパターン（便宜上、通常の賃金と呼びます）で、２つ目は平均賃金、３つ目は健康保険法40条第1項で定める標準報酬月額の30分の1に相当する金額という選択肢があります。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">
        Q
        学生時代からアルバイトをしていた者が卒業と同時に正社員として入社した。この場合、正社員としての入社日を基準として年休の付与日数を算出する。
      </h1>
      <p>
        A 誤り。
        <span>
          実質的に労使関係が続いている場合は学生時代のアルバイトであっても通算しなければなりません。
        </span>
        行政解釈上も継続勤務とは労働契約の存在期間であり、すなわち「在籍期間」ということです。もちろん学生でなくてもアルバイトとしての契約を２か月で終了し、その後正社員に登用となった場合も同様にアルバイトの期間を通算して年休付与日数は算出されます。
      </p>

      <h1 id="toc4">
        Q
        6か月後に退職することが明らかな者（正社員とする）にも6か月継続勤務時には10日の年休を付与しなければならない。
      </h1>
      <p>
        A
        正解。もちろんすべての年休を使いきれない場合の方が多いのでしょうが、法律上は6か月継続勤務し、その間の出勤率が8割以上の者に対して10日の年休を付与する旨を定めていますので、残りの労働日数の長短に関わらず、法律上の要件を満たした場合、雇用形態に関わらず基準日に所定の年休を付与しなければなりません。
      </p>

      <h1 id="toc5">
        Q
        有給休暇５日取得義務を満たせなかった労働者が２名いた場合、取得日数が２日少ない労働者については罰金額が増える。
      </h1>
      <p>誤り。</p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0053
